<template>
  <div>
    <link rel="stylesheet" type="text/css" href="/flags-css/css/flag-icon.min.css" />
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <Header :event="event"/>
      <v-card-title class="headline">Translations for {{event.name}}</v-card-title>

      <v-card-text>      
        <p>
          Use this page to translate localized content for this event. 
        </p>
        <p>
          Default language: <v-chip v-if="event.default_lang" color="primary">{{event.default_lang}}</v-chip><br/>
          Other available language(s): <v-chip v-if="event.available_langs" v-for="item in event.available_langs" color="primary" outlined class="ms-1" :key="item">{{item}}</v-chip>
        </p>
      </v-card-text>      

      <v-data-table
        :headers="headers"
        :items="fields"
        item-key="key"
        sort-by="rank"
        group-by="section"
        :items-per-page="1000"
        :show-group-by="false"
        hide-default-footer
      >
        <template v-slot:group.header="{items, isOpen, toggle}">
          <th colspan="99" class="light-gray">
            
            <h3 class="mb-0 mt-4">
              <v-icon small class="me-2" @click="toggle">{{ isOpen ? 'fa-minus' : 'fa-plus' }}</v-icon>
              <!-- <v-icon small class="m1-2" >{{ (groupings.find(g => g.key === items[0].section)|| {}).icon}}</v-icon> -->
              {{ (groupings.find(g => g.key === items[0].section)|| {}).header || items[0].section}}
            </h3>
            <!-- Translations for {{ items[0].header }}</strong> -->
          </th>
        </template>
        <template v-slot:item.name="{ item }">
          {{item.name}}
        </template>
        <template v-slot:item.default="{ item }">
          <span v-if="item.default">{{item.default}}</span>
          <i v-else>- empty -</i>
        </template>
        <template v-slot:item.values="{ item }">
          <span v-if="item.values">
            <span v-for="lang in Object.keys(item.values)" :key="lang" class="me-2">
              <!-- note: flags are not great for languages, e.g. `ar` is arabic, not Argentia -->
              <!-- <span :title="`${lang}: ${item.values[lang]}`" :class="`me-0 flag-icon flag-icon-${lang}`"></span> -->
              <v-chip color="accent" outlined :title="`${lang}: ${item.values[lang]}`">{{lang}}</v-chip>
            </span>
            <!-- {{item.values}} -->
          </span>
          <i v-else>- no translation(s) -</i>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="me-2"
            @click="editItem(item)"
          >
            fa-pencil
          </v-icon>
        </template>            
      </v-data-table>

      <v-dialog v-if="editedItem" v-model="showTranslateDialog" max-width="600">
        <v-card>
          <v-card-title>Edit translations for {{ editedItem.name}}</v-card-title>
          <v-card-text>
            <v-textarea v-if="editedItem.multi_line" v-model="editedItem.default" disabled readonly label="Default text" auto-grow rows="1" />
            <v-text-field v-else v-model="editedItem.default" disabled readonly label="Default text" />

            <h3>Translations</h3>
            <div v-for="lang in this.event.available_langs" :key="lang"> 
              <v-textarea v-if="editedItem.multi_line" v-model="editedItem.values[lang]" :label="`Translation in ${lang}`" auto-grow rows="1" />
              <v-text-field v-else v-model="editedItem.values[lang]" :label="`Translation in ${lang}`" />
            </div>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" large @click="saveItem">Save</v-btn>
            <v-spacer />
            <v-btn color="primary" text @click="showTranslateDialog=false">Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
    <MarkdownEditor v-if="editedItem" ref="editor" v-model="editedItem.content" :placeholders="editedItem.placeholders" :description="editedItem.editorDescription" style="display:none;"/>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import eventManagerService from "@/services/eventManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventUtil from "@/util/eventUtil";
import siteData from '@/data/site.json'
import Header from './_Header.vue'
import MarkdownEditor from "@/components/MarkdownEditor";

export default {
  name: "Certificate",
  components: {
    Header,
    MarkdownEditor,
  },
  props: {
  },
  data() {
    return {
      event: null,
      localized: null,
      groupings: [],
      fields: [],
      siteData: siteData,
      headers: [],
      showTranslateDialog: false,
      editedItem: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async editItem(item) {
      console.log('/// edit', item, 'fn', item.onEdit);
      if (item.values == null) {
        item.values = {};
      }
      this.editedItem = item;
      this.showTranslateDialog = true;
    },

    async saveItem(item) {
      if (this.editedItem.onEdit) this.editedItem.onEdit(this.editedItem.values);

      this.save();
      this.showTranslateDialog = false;
    },

    async getEvent(id) {
      this.event = (await eventManagerService.get(id)).data;
      this.localized = (await eventManagerService.getLocalized(id)).data;
      
      if (!this.localized.races) {
        this.localized.races = [];
      }
      // Prepare all races and other structure
      for (var race of (this.event.races || [])) {
        if (!this.localized.races.some(x => x.id == race.id)) {
          this.localized.races.push({id: race.id });
        }
      }

      this.event.available_langs = this.event.available_langs || [];

      this.headers = [
        { text: 'Field', value: 'name' },
        { text: `Default (${this.event.default_lang})`, value: 'default' },
        { text: `Translations`, value: 'values' },
        { text: ``, value: 'actions' },
      ]
      
      var section = { key: 'event', header: 'Event', fields: this.eventFields, icon: 'fal fa-home' };
      this.groupings.push(section);
      this.fields = this.fields.concat([
        {
          key: `${section.key}.name`,
          section: section.key,
          name: "Name",
          default: this.event.name,
          values: this.localized.name,
          onEdit: x => this.localized.name = x,
        },
        {
          key: `${section.key}.intro`,
          section: section.key,
          name: "Intro",
          multi_line: true,
          default: this.event.intro,
          values: this.localized.intro,
          onEdit: x => this.localized.intro = x,
        },
        {
          key: `${section.key}.description`,
          section: section.key,
          name: "Description",
          multi_line: true,
          default: this.event.description,
          values: this.localized.description,
          onEdit: x => this.localized.description = x,
        },
        {
          key: `${section.key}.rules_desc`,
          section: section.key,
          name: "Rules",
          multi_line: true,
          default: this.event.rules_desc,
          values: this.localized.rules_desc,
          onEdit: x => this.localized.rules_desc = x,
        },
        {
          key: `${section.key}.instructions`,
          section: section.key,
          name: "Getting Started Instruction",
          multi_line: true,
          default: this.event.instructions,
          values: this.localized.instructions,
          onEdit: x => this.localized.instructions = x,
        },
      ]);
      
      var section = { key: 'event.onboarding', header: 'Event Onboarding', fields: this.eventFields, icon: 'fal fa-home' };
      this.groupings.push(section);
      this.fields = this.fields.concat([
        {
          key: `${section.key}.onboarding_msg`,
          section: section.key,
          name: "Onboarding message",
          default: this.event.onboarding_msg,
          values: this.localized.onboarding_msg,
          onEdit: x => this.localized.onboarding_msg = x,
        },
        {
          key: `${section.key}.join_msg`,
          section: section.key,
          name: "Join Message",
          default: this.event.join_msg,
          values: this.localized.join_msg,
          onEdit: x => this.localized.join_msg = x,
        },
        {
          key: `${section.key}.join_consent_msg`,
          section: section.key,
          name: "Consent Message",
          multi_line: true,
          default: this.event.join_consent_msg,
          values: this.localized.join_consent_msg,
          onEdit: x => this.localized.join_consent_msg = x,
        },
        {
          key: `${section.key}.join_race_msg`,
          section: section.key,
          name: "Select Leaderboard Message",
          default: this.event.join_race_msg,
          values: this.localized.join_race_msg,
          onEdit: x => this.localized.join_race_msg = x,
        },
        {
          key: `${section.key}.join_gender_msg`,
          section: section.key,
          name: "Select Gender Message",
          default: this.event.join_gender_msg,
          values: this.localized.join_gender_msg,
          onEdit: x => this.localized.join_gender_msg = x,
        },
        {
          key: `${section.key}.join_team_msg`,
          section: section.key,
          name: "Select Team Message",
          default: this.event.join_team_msg,
          values: this.localized.join_team_msg,
          onEdit: x => this.localized.join_team_msg = x,
        },
        {
          key: `${section.key}.udf_1_field.label`,
          section: section.key,
          name: "Custom question 1: label",
          default: this.event.join_udf_1_field && this.event.join_udf_1_field.label,
          values: this.localized.join_udf_1_field && this.localized.join_udf_1_field.label,
          onEdit: x => {
            if (!this.localized.join_udf_1_field) {
              this.localized.join_udf_1_field = {};
            }
            this.localized.join_udf_1_field.label = x;
          } 
        },
        {
          key: `${section.key}.udf_1_field.hint`,
          section: section.key,
          name: "Custom question 1: hint",
          default: this.event.join_udf_1_field && this.event.join_udf_1_field.hint,
          values: this.localized.join_udf_1_field && this.localized.join_udf_1_field.hint,
          onEdit: x => {
            if (!this.localized.join_udf_1_field) {
              this.localized.join_udf_1_field = {};
            }
            this.localized.join_udf_1_field.hint = x;
          } 
        },
      ]);
      
      var section = { key: 'event.customization', header: 'Event Customization', fields: this.eventFields, icon: 'fal fa-home' };
      this.groupings.push(section);
      this.fields = this.fields.concat([
        {
          key: `${section.key}.url`,
          section: section.key,
          name: "Website (url)",
          default: this.event.url,
          values: this.localized.url,
          onEdit: x => this.localized.url = x,
        },
        {
          key: `${section.key}.support_url`,
          section: section.key,
          name: "Support website (url)",
          default: this.event.support_url,
          values: this.localized.support_url,
          onEdit: x => this.localized.support_url = x,
        },
        {
          key: `${section.key}.support_email`,
          section: section.key,
          name: "Support email",
          default: this.event.support_email,
          values: this.localized.support_email,
          onEdit: x => this.localized.support_email = x,
        },
        {
          key: `${section.key}.external_url`,
          section: section.key,
          name: "External (results) website (url)",
          default: this.event.external_url,
          values: this.localized.external_url,
          onEdit: x => this.localized.external_url = x,
        },
        {
          key: `${section.key}.referral_msg`,
          section: section.key,
          name: "Referral Message",
          multi_line: true,
          default: this.event.referral_msg,
          values: this.localized.referral_msg,
          onEdit: x => this.localized.referral_msg = x,
        },
        {
          key: `${section.key}.reg_text`,
          section: section.key,
          name: "CTA button text",
          default: this.event.reg_text,
          values: this.localized.reg_text,
          onEdit: x => this.localized.reg_text = x,
        },
        {
          key: `${section.key}.reg_url`,
          section: section.key,
          name: "CTA button url",
          default: this.event.reg_url,
          values: this.localized.reg_url,
          onEdit: x => this.localized.reg_url = x,
        },
      ]);

      var section = { key: 'event.emails', header: 'Event Emails', fields: this.eventFields, icon: 'fal fa-envelope' };
      this.groupings.push(section);
      this.fields = this.fields.concat([
        {
          key: `${section.key}.invite_email_text`,
          section: section.key,
          name: "Invite Email",
          multi_line: true,
          default: this.event.invite_email_text,
          values: this.localized.invite_email_text,
          onEdit: x => this.localized.invite_email_text = x,
        },
        {
          key: `${section.key}.join_confirmation_email_template`,
          section: section.key,
          name: "Join Confirmation Email",
          multi_line: true,
          default: this.event.join_confirmation_email_template,
          values: this.localized.join_confirmation_email_template,
          onEdit: x => this.localized.join_confirmation_email_template = x,
        },
        {
          key: `${section.key}.bib_email_text`,
          section: section.key,
          name: "Bib Email",
          multi_line: true,
          default: this.event.bib_email_text,
          values: this.localized.bib_email_text,
          onEdit: x => this.localized.bib_email_text = x,
        },
        {
          key: `${section.key}.referral_email_template`,
          section: section.key,
          name: "Referral Email",
          multi_line: true,
          default: this.event.referral_email_template,
          values: this.localized.referral_email_template,
          onEdit: x => this.localized.referral_email_template = x,
        },
      ]);

      for (var race of this.event.races) {
        var localizedRace = this.localized.races.find(y => y.id == race.id);
        if (!localizedRace) {
          localizedRace = { id: race.id };
          this.localized.races.push(localizedRace);
        }
        localizedRace.badges = localizedRace.badges || [];
        localizedRace.result_cols = localizedRace.result_cols || [];
        localizedRace.tiers = localizedRace.tiers || [];

        // Generic
        var section = { key: `${this.event.id}.${race.id}`, header: `Leaderboard ${race.name}`, icon: 'fal fa-trophy' }
        this.groupings.push(section);
        this.fields = this.fields.concat([
          {
            section: section.key,
            key: `${section.key}.name`,
            name: "Name",
            description: "Display name of this leaderboard.",
            default: race.name,
            values: localizedRace.name,
            onEdit: x => localizedRace.name = x,
          },
          {
            section: section.key,
            key: `${section.key}.result_desc`,
            name: "Leaderboard description",
            description: "Leaderboard description.",
            multi_line: true,
            default: race.result_desc,
            values: localizedRace.result_desc,
            onEdit: x => localizedRace.result_desc = x,
          },
          {
            section: section.key,
            key: `${section.key}.result_completed_desc`,
            name: "Completed Result Message",
            multi_line: true,
            default: race.result_completed_desc,
            values: localizedRace.result_completed_desc,
            onEdit: x => localizedRace.result_completed_desc = x,
          },
          {
            section: section.key,
            key: `${section.key}.route_desc`,
            name: "Route description",
            multi_line: true,
            default: race.route_desc,
            values: localizedRace.route_desc,
            onEdit: x => localizedRace.route_desc = x,
          },
          {
            section: section.key,
            key: `${section.key}.goal_desc`,
            name: "Goal Description",
            multi_line: true,
            default: race.goal_desc,
            values: localizedRace.goal_desc,
            onEdit: x => localizedRace.goal_desc = x,
          },
          {
            section: section.key,
            key: `${section.key}.scoring_desc`,
            name: "Scoring Description",
            multi_line: true,
            default: race.scoring_desc,
            values: localizedRace.scoring_desc,
            onEdit: x => localizedRace.scoring_desc = x,
          },
          {
            section: section.key,
            key: `${section.key}.custom`,
            name: "Custom scoring value",
            default: race.custom,
            values: localizedRace.custom,
            onEdit: x => localizedRace.custom = x,
          },
          {
            section: section.key,
            key: `${section.key}.referral_msg`,
            name: "Referral Message",
            default: race.referral_msg,
            values: localizedRace.referral_msg,
            onEdit: x => localizedRace.referral_msg = x,
          },
          {
            section: section.key,
            key: `${section.key}.relay_join_instructions`,
            name: "Relay team join instructions",
            multi_line: true,
            default: race.relay_join_instructions,
            values: localizedRace.relay_join_instructions,
            onEdit: x => localizedRace.relay_join_instructions = x,
          },
          {
            section: section.key,
            key: `${section.key}.external_url`,
            name: "External (results) website (url)",
            default: race.external_url,
            values: localizedRace.external_url,
            onEdit: x => localizedRace.external_url = x,
          },
        ]);

        // Emails
        var section = { key: `${this.event.id}.${race.id}.emails`, header: `Leaderboard ${race.name} - Emails`, icon: 'fal fa-envelope' }
        this.groupings.push(section);
        this.fields = this.fields.concat([
          {
            section: section.key,
            key: `${section.key}.completed_email_text`,
            name: "Completion Email",
            multi_line: true,
            default: race.completed_email_text,
            values: localizedRace.completed_email_text,
            onEdit: x => localizedRace.completed_email_text = x,
          },
          {
            section: section.key,
            key: `${section.key}.certificate_email_text`,
            name: "Certificate Email",
            multi_line: true,
            default: race.certificate_email_text,
            values: localizedRace.certificate_email_text,
            onEdit: x => localizedRace.certificate_email_text = x,
          },
          {
            section: section.key,
            key: `${section.key}.referral_email_template`,
            name: "Referral Email",
            multi_line: true,
            default: race.referral_email_template,
            values: localizedRace.referral_email_template,
            onEdit: x => localizedRace.referral_email_template = x,
          },
        ]);

        // Columns
        var columns = (await eventManagerService.getResultColumns(this.event.id, race.id)).data.data || [];
        var section = { key: `${this.event.id}.${race.id}.columns`, header: `Leaderboard ${race.name} - Columns`, icon: 'fal fa-home'};
        for (var col of columns) {
          var localizedCol = localizedRace.result_cols.find(y => y.type == col.type);
          if (!localizedCol) {
            localizedCol = { type: col.type };
            localizedRace.result_cols.push(localizedCol);
          }
          this.fields = this.fields.concat([
            {
              key: `${section.key}.result_cols.${col.type}.${col.badge_id}.caption`,
              section: section.key,
              name: `${col.type}: Caption`,
              default: col.caption,
              values: localizedCol.caption,
              onEdit: x => localizedCol.caption = x,
            },
          ]);
        }
        this.groupings.push(section);

        // Tiers
        var section = { key: `${this.event.id}.${race.id}.tiers`, header: `Leaderboard ${race.name} - Tiers`, icon: 'fal fa-home'};
        for (var tier of race.tiers || []) {
          var localizedTier = localizedRace.tiers.find(y => y.value == tier.value);
          if (!localizedTier) {
            localizedTier = { value: tier.value };
            localizedRace.tiers.push(localizedTier);
          }
          this.fields = this.fields.concat([
            {
              key: `${section.key}.tiers.${tier.value}.caption`,
              section: section.key,
              name: `${tier.name}: Caption`,
              default: tier.name,
              values: localizedTier.name,
              onEdit: x => localizedTier.name = x,
            },
          ]);
        }
        this.groupings.push(section);

        // Badges
        var badges = (await eventManagerService.getBadges(this.event.id, race.id)).data.data || [];
        var section = { key: `${this.event.id}.${race.id}.badges`, header: `Leaderboard ${race.name} - Badges`, icon: 'fal fa-home'};
        var fields = [];
        for (var badge of badges) {
          var localizedBadge = localizedRace.badges.find(y => y.id == badge.id);
          if (!localizedBadge) {
            localizedBadge = { id: badge.id };
            localizedRace.badges.push(localizedBadge);
          }
          fields = fields.concat([
            {
              key: `${section.key}.badges.${badge.id}.name`,
              section: section.key,
              name: `${badge.name}: Name`,
              description: ".",
              default: badge.name,
              values: localizedBadge.name,
              onEdit: x => localizedBadge.name = x,
            },
            {
              key: `${section.key}.badges.${badge.id}.description`,
              section: section.key,
              name: `${badge.name}: Description`,
              multi_line: true,
              default: badge.description,
              values: localizedBadge.description,
              onEdit: x => localizedBadge.description = x,
            },
            {
              key: `${section.key}.badges.${badge.id}.email_template`,
              section: section.key,
              name: `${badge.name}: Email`,
              multi_line: true,
              default: badge.email_template,
              values: localizedBadge.email_template,
              onEdit: x => localizedBadge.email_template = x,
            },
          ]);
        }
        this.groupings.push(section);
        this.fields = this.fields.concat(fields);
      }

      var i = 0;
      for (var field of this.fields) {
        field.rank = i++;
      }
      // filter out empty ones
      this.fields = this.fields.filter(x => !!x.default);
      //console.log('/// localized', this.localized, this.fields);
    },
    async save() {
      var result = (await eventManagerService.saveLocalized(this.event.id, this.localized)).data;
      this.$helpers.toastResponse(this, result, 'Save successful');
    },
    async customizeContent(item) {
      //console.log('CONTENT', item.title, item.content);
      this.editedItem = item;
      await this.$nextTick();
      this.$refs.editor.openEditor();
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id, this.$route.params.raceId);
      }
    },


  },
  computed: {
    eventUtil() {
      return new EventUtil(this, this.event);
    },

    breadcrumbItems() {
      return !this.event ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: this.event.name, exact: true, to: { name: 'eventmanagerView', params: {id: this.event.id}} },
        { text: 'Translations', disabled: true },
      ];
    },

    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
.col-lang { width: 100px; max-width: 100px;;}
</style>

